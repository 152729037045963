import Input from "@/components/input";

export default {
    name: "intelligence-model",
    components: {
        "neo-input": Input,
    },
    data() {
        return {
            title: "Intelligence Cycles",
            filters: {
                search: "",
            },
            headers: [
                {name: "URL", key: "url", widthFr: 2},
                {name: "Type", key: "type", widthFr: 1},
                {name: "Last Checked Date", key: "last_checked_date", widthFr: 1},
                {name: "Status", key: "done", widthFr: 1},
                {name: "Upcoming Date", key: "upcoming_date", widthFr: 1},
                {name: "Remaining Days", key: "remaining_days", widthFr: 1},
                {name: "Monitoring Duration", key: "monitoring_duration", widthFr: 1},
                {name: "Notes", key: "notes", widthFr: 1},
            ],

            sortObj: {
                sortKey: null,
                reversed: false,
            },

            tableData: [
                {
                    url: "abc",
                    type: "abc",
                    last_checked_date: "123",
                    done: false,
                    upcoming_date: "123",
                    remaining_days: "5",
                    monitoring_duration: "10",
                    notes: "abc abc",
                },
            ],
        };
    },
    computed: {
        getData() {
            return this.tableData;
        },
    },
    mounted() {
        this.$refs.contentTable.style.height = `${this.$refs.contentContainer.offsetHeight - 200}px`;
    },
    methods: {},
};
